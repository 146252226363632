<template>
  <!-- 政策速递 -->
  <div id="policy-express">
    <div class="header">
      <h2 class="title">政策速递</h2>
      <div class="header-btn">
        <el-button type="primary" size="mini" @click="pushSet">推送设置</el-button>
        <el-button type="primary" size="mini" @click="addConpany">添加企业</el-button>
      </div>
      <div class="header-back">
        <el-button type="primary" size="mini" @click="backBtn">返回</el-button>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column align="center" prop="logo" label="企业logo">
          <template slot-scope="scope">
            <img :src="scope.row.logo" alt width="60" height="40" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="company_name" label="企业名称"></el-table-column>
        <el-table-column align="center" prop="company_brand" label="品牌名称"></el-table-column>
        <el-table-column align="center" width="300" prop="industry" label="行业领域">
          <template slot-scope="scope">
            <el-tag v-for="(item,i) in scope.row.industry" :key="i">{{item.title}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="80" prop="stage.title" label="融资情况"></el-table-column>
        <el-table-column align="center" width="80" prop="push_times" label="政策接受数"></el-table-column>
        <el-table-column align="center" width="80" prop="push_style" label="接收形式">
          <template slot-scope="scope">
            <span>{{scope.row.push_style=='email'?'邮箱':''}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="160">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit( scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleRemove( scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page
        style="text-align:center"
        :total="page.total"
        @currentPageChange="currentPageChange"
        :page="page.current_page"
      />
    </div>
    <!-- 推送设置弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      title="推送设置"
      center
      :visible.sync="pushSetDiag"
      width="750px"
      @close="closeAddForm('pushForm')"
    >
      <el-form
        ref="pushForm"
        :rules="pushRules"
        :model="pushForm"
        label-width="140px"
        label-position="right"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="自动推送" label-width="100px">
              <el-switch :active-value="1" :inactive-value="0" v-model="pushForm.is_auto"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="重点政策推送匹配度" label-width="150px" prop="min_match">
              <el-input type="number" v-model="pushForm.min_match" style="width:65px"></el-input>&nbsp;%
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="发送形式" prop="type">
              <el-select v-model="pushForm.type" placeholder="请选择" style="width:110px">
                <el-option label="邮件" value="email"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="推送周期（天）" prop="circle">
              <el-input type="number" v-model="pushForm.circle" style="width:110px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="起始时间" prop="start_time">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="pushForm.start_time"
                style="width:150px"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pushSetDiag = false">取 消</el-button>
        <el-button type="primary" @click="savePushForm('pushForm')">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 添加企业弹窗 -->
    <el-dialog
      title="添加企业"
      center
      :visible.sync="addConpanyDiag"
      width="870px"
      @close="closeAddForm('addForm')"
      :close-on-click-modal="false"
    >
      <el-form ref="addForm" :model="addForm" label-width="100px" :rules="rules">
        <div class="add-form">
          <el-row class="row-1">
            <el-form-item label="企业logo:" label-width="80px" prop="logo">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :before-upload="beforeUpload"
                accept=".jpg, .png, .jepg"
              >
                <img v-if="addForm.logo" :src="addForm.logo" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <div class="logo-info">建议上传大小为1.2：1的图片；支持格式:jpg、jepg、png,大小不超过4M</div>
          </el-row>
          <el-row class="row-2">
            <el-col :span="12">
              <el-form-item label="企业名称:" prop="company_name">
                <el-input v-model="addForm.company_name" style="width:140px"></el-input>
              </el-form-item>
              <el-form-item style="margin-top:41px" label></el-form-item>
              <el-form-item label="品牌名称:">
                <el-input v-model="addForm.company_brand" style="width:140px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="行业领域:" prop="industry">
                <el-radio-group v-model="addForm.industry_type" @change="changeCategory">
                  <el-radio :label="2">创投行业分类</el-radio>
                  <el-radio :label="1">国民经济行业分类</el-radio>
                </el-radio-group>
                <el-cascader
                  v-if="addForm.industry_type==2"
                  v-model="addForm.industry"
                  :options="options"
                  :props="{ multiple: true, checkStrictly: true, value:'id',label:'title'}"
                  :show-all-levels="false"
                ></el-cascader>
                <el-cascader
                  v-else
                  v-model="addForm.industry"
                  :options="options"
                  :props="{ multiple: true, checkStrictly: true, value:'pkid',label:'title'}"
                  :show-all-levels="false"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="融资轮次:" prop="stage_id">
                <el-select v-model="addForm.stage_id" placeholder="请选择" style="width:140px">
                  <el-option
                    v-for="item in stages"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :span="16" class="company-address" style="display:flex">
            <el-form-item label="企业详细地址:" prop="province_id" label-width="110px">
              <el-select
                v-model="addForm.province_id"
                @change="selectProvice"
                placeholder="省"
                style="width:90px"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="0px" prop="city_id">
              <el-select
                v-model="addForm.city_id"
                @change="selectCity"
                placeholder="市"
                style="width:90px"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="0" prop="county_id">
              <el-select v-model="addForm.county_id" placeholder="区" style="width:90px">
                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="0" prop="address">
              <el-input placeholder="请输入详细地址" v-model="addForm.address" style="width:150px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="融所属园区:" prop="park_name">
              <el-input v-model="addForm.park_name" style="width:140px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="1">
            <el-form-item label="企业联系方式:" label-width="65px"></el-form-item>
          </el-col>
          <el-col :span="7" class="contact">
            <el-form-item label="联系人:" label-width="85px" prop="contact_people">
              <el-input v-model="addForm.contact_people" style="width:140px"></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" label-width="85px" prop="contact">
              <el-input type="number" v-model="addForm.contact" style="width:140px"></el-input>
            </el-form-item>
            <el-form-item label="邮箱:" label-width="85px" prop="email">
              <el-input v-model="addForm.email" style="width:140px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="其他信息: " label-width="85px"></el-form-item>
          </el-col>
          <el-col :span="13" class="other-msg">
            <el-row>
              <el-col :span="12">
                <el-form-item label-width="80px" label="企业规模:">
                  <el-select
                    v-model="addForm.policy_scale_id"
                    placeholder="请选择"
                    style="width:130px"
                  >
                    <el-option
                      v-for="item in scales"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="80px" label="人员规模:">
                  <el-input type="number" v-model="addForm.staff_count" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label-width="80px" label="资产总额:">
                  <el-input type="number" v-model="addForm.total_capital" style="width:90px"></el-input>&nbsp;万元
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="90px" label="总体行业:">
                  <el-select
                    v-model="addForm.scale_industry_id"
                    placeholder="请选择"
                    style="width:130px"
                  >
                    <el-option
                      v-for="item in industries"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="90px" label="企业营收:">
                  <el-input type="number" v-model="addForm.total_income" style="width:90px"></el-input>&nbsp;万元
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddForm('addForm')">取 消</el-button>
        <el-button type="primary" @click="submitAddForm('addForm')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import page from "../../../components/PageComponents";
import { success, warning } from "../../../utils/notification";
import { checkEmail } from "../../../utils/validata";
export default {
  name: "",
  components: { page },
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!checkEmail(value)) {
        callback(new Error("邮箱格式有误"));
      } else {
        callback();
      }
    };
    return {
      organ_id: "", //机构ID
      tableData: [], //表格数据
      pushSetDiag: false, //推送设置弹窗
      addConpanyDiag: false, //推送设置弹窗
      page: {
        current_page: 0,
        total: 0,
        last_page: 0
      }, //分页数据
      nowPage: 1, //当前页
      pushForm: {
        organ_id: "",
        is_auto: 0,
        min_match: "",
        type: "email",
        circle: "",
        start_time: ""
      }, //推送表单
      addForm: { industry: [] }, //添加表单
      imageUrl: "", //上传地址
      scales: [], //企业规模列表
      industries: [], //总体行业
      stages: [], //融资轮次
      provinces: [], //省份
      cities: [], //城市
      areas: [], //地区
      options: [], //级联分类领域
      uploadUrl: "", //图片上传地址
      edit: false, //是否为编辑状态
      company_id: 0, //企业ID
      rules: {
        logo: [{ required: true, message: "请上传图片", trigger: "blur" }],
        company_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 1,
            max: 40,
            message: "长度在 1 到 40 个字符",
            trigger: "change"
          }
        ],
        industry: [
          { required: true, message: "请选择行业领域", trigger: "change" }
        ],
        stage_id: [
          { required: true, message: "请选择融资轮次", trigger: "change" }
        ],
        province_id: [
          {
            required: true,
            message: "请选择省份",
            trigger: "change"
          }
        ],
        city_id: [
          {
            required: true,
            message: "请选择城市",
            trigger: "change"
          }
        ],
        county_id: [
          {
            required: true,
            message: "请选择地区",
            trigger: "change"
          }
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          {
            min: 1,
            max: 40,
            message: "长度在 1 到 40 个字符",
            trigger: "change"
          }
        ],
        park_name: [
          { required: true, message: "请输入园区", trigger: "blur" },
          {
            min: 1,
            max: 40,
            message: "长度在 1 到 40 个字符",
            trigger: "change"
          }
        ],
        contact_people: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "change"
          }
        ],
        contact: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度为11个数字号码",
            trigger: "blur"
          }
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: validateEmail, trigger: "blur" }
        ]
      },
      pushRules: {
        start_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change"
          }
        ],
        circle: [{ required: true, message: "请输入天数", trigger: "blur" }],
        min_match: [
          { required: true, message: "请输入匹配度", trigger: "blur" }
        ],
        type: [{ required: true, message: "请选择发送形式", trigger: "change" }]
      }
    };
  },
  created() {
    this.uploadUrl = this.URL.upLoad;

    this.organ_id = sessionStorage.organizationId;
    this.getCompanyList(this.organ_id, 1); //公司列表
  },
  methods: {
    //返回按钮
    backBtn() {
      window.history.go(-1);
    },
    //推荐设置
    pushSet() {
      this.pushSetDiag = true;
      this.getPushSet(this.organ_id);
    },
    //添加企业
    addConpany() {
      this.addConpanyDiag = true;
      this.edit = false;
      this.addForm = {
        city_id: "",
        county_id: "",
        logo: "",
        organ_id: "",
        company_name: "",
        company_brand: "",
        email: "",
        contact_people: "",
        contact: "",
        stage_id: "",
        province_id: "",
        address: "",
        park_name: "",
        industry_type: "",
        policy_scale_id: "",
        staff_count: "",
        total_capital: "",
        total_income: "",
        scale_industry_id: "",
        industry: []
      };
      this.options = [];
      this.getCompanyScale(1); //企业规模列表
      this.getIndustry(1); //总体行业
      this.getstages(1); //融资轮次
      this.getPublic(0); //地区--省份
    },
    //企业列表
    async getCompanyList(organ_id, page, limit) {
      const res = await this.$http.get(this.URL.adminUrl.pushCompanies, {
        params: {
          organ_id,
          page,
          limit
        }
      });
      this.tableData = res.data.data.data;
      this.page = res.data.data.meta;
    },
    //分页
    currentPageChange(page) {
      this.nowPage = page;
      this.getCompanyList(this.organ_id, page);
    },
    //企业规模
    async getCompanyScale(page) {
      const res = await this.$http.get(this.URL.adminUrl.policy, {
        params: {
          page
        }
      });
      this.scales = res.data.data;
    },
    //总体行业
    async getIndustry(page) {
      const res = await this.$http.get(this.URL.adminUrl.industry, {
        params: {
          page
        }
      });
      this.industries = res.data.data;
    },
    //融资轮次
    async getstages(page) {
      const res = await this.$http.get(this.URL.adminUrl.stagesList, {
        params: {
          page
        }
      });
      this.stages = res.data.data;
    },
    //地区--公共函数
    async getPublic(flag, city_id) {
      const res = await this.$http.get(this.URL.getArea, {
        params: { city_id }
      });
      if (flag == 0) {
        this.provinces = res.data.data;
        this.cities = this.areas = [];
      }
      if (flag == 1) {
        this.cities = res.data.data;
        this.areas = [];
      }
      flag == 2 && (this.areas = res.data.data);
    },
    //选择省--出现城市
    selectProvice(id) {
      this.cities = this.areas = [];
      this.addForm.county_id = this.addForm.city_id = "";
      this.getPublic(1, id);
    },
    //选择城市--出现地区
    selectCity(id) {
      this.areas = [];
      this.addForm.county_id = "";
      this.getPublic(2, id);
    },
    //选择分类领域
    changeCategory(id) {
      id == 1 && this.getCategories("economics");
      id == 2 && this.getCategories("starts");
      this.addForm.industry = [];
    },
    //分类 - 创投和国民行业列表
    async getCategories(url) {
      const res = await this.$http.get(this.URL.adminUrl[url], {});
      this.options = res.data.data;
    },
    //上传前
    beforeUpload(file) {
      var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!testmsg) {
        this.$message.error("上传图片格式不对!");
        return;
      }
      if (!isLt4M) {
        this.$message.error("上传头像图片大小不能超过 4MB!");
      }
      return testmsg && isLt4M;
    },
    //图片上传成功
    uploadSuccess(res) {
      this.addForm.logo = res.data.path;
      success("图片上传成功");
    },
    //关闭弹窗事件
    closeAddForm(formName) {
      this.$refs[formName].resetFields();
    },
    //取消按钮事件
    cancelAddForm(formName) {
      this.addConpanyDiag = false;
    },
    //添加企业表单提交
    submitAddForm(formName) {
      this.addForm.organ_id = this.organ_id;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.addForm.industry.length > 3) {
            warning("行业领域最多选择3个");
          } else {
            let arr = [];
            this.addForm.industry.forEach(item => {
              arr.push(item[item.length - 1]);
            });
            this.addForm.industry = arr;
            this.addForm.organ_id = Number(this.addForm.organ_id);
            this.edit &&
              this.$http
                .put(
                  this.URL.adminUrl.pushCompanies + this.company_id,
                  this.addForm
                )
                .then(res => {
                  this.addConpanyDiag = false;
                  success("企业编辑成功");
                  this.getCompanyList(this.organ_id, this.nowPage);
                });
            !this.edit &&
              this.$http
                .post(this.URL.adminUrl.pushCompanies, this.addForm)
                .then(res => {
                  this.addConpanyDiag = false;
                  success("企业添加成功");
                  this.getCompanyList(this.organ_id, this.nowPage);
                });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑
    handleEdit(data) {
      this.addConpanyDiag = true;
      this.edit = true;
      this.getCompanyScale(1); //企业规模列表
      this.getIndustry(1); //总体行业
      this.getstages(1); //融资轮次
      this.getPublic(0); //地区--省份
      this.company_id = data.id;
      this.getcompanies(this.organ_id, data.id);
    },
    //移除
    handleRemove(data) {
      this.$confirm(
        `此操作将移除企业 " ${data.company_name} ", 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$http
          .put(this.URL.adminUrl.pushToggle, {
            organ_id: this.organ_id,
            company_id: data.id
          })
          .then(res => {
            success("移除成功");
            this.getCompanyList(this.organ_id, this.nowPage);
          });
      });
    },
    //推送设置保存
    savePushForm(formName) {
      this.pushForm.organ_id = this.organ_id;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http
            .post(this.URL.adminUrl.pushSet, this.pushForm)
            .then(res => {
              this.pushSetDiag = false;
              success("推送设置保存成功");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取推送配置
    async getPushSet(organ_id) {
      const res = await this.$http.get(this.URL.adminUrl.pushSet, {
        params: { organ_id }
      });
      res.data.data && (this.pushForm = res.data.data);
    },
    //获取企业完整信息
    async getcompanies(organ_id, company_id) {
      const res = await this.$http.get(
        this.URL.adminUrl.pushCompanies + company_id,
        {
          params: { organ_id }
        }
      );
      this.addForm = res.data.data;
      if (this.addForm.industry_type == 1) {
        this.getCategories("economics");
      } else {
        this.getCategories("starts");
      }
      this.getPublic(0);
      this.getPublic(1, this.addForm.province_id);
      this.getPublic(2, this.addForm.city_id);
    }
  }
};
</script>
<style lang="less" scoped>
#policy-express {
  padding: 0 20px;
  // 头部
  .header {
    .title {
      text-align: center;
    }
    .header-btn {
      text-align: right;
    }
  }
  //内容
  .content {
    margin-top: 20px;
  }
  //弹窗
  .add-form {
    display: flex;
    .row-1 {
      width: 35%;
      display: flex;
      .logo-info {
        width: 60px;
        margin: 20px 0 0 10px;
        color: rgba(118, 122, 122, 0.329);
      }
    }
    .row-2 {
      width: 72%;
    }
  }
  .company-address {
    .el-form-item {
      margin-right: 2px;
    }
  }
  .contact {
    border: 1px solid rgb(231, 216, 216);
    margin-left: 25px;
    padding: 20px 15px 0 0;
    border-radius: 10px;
  }
  .other-msg {
    border: 1px solid rgb(231, 216, 216);
    margin-left: 8px;
    padding: 20px 10px 0 0;
    border-radius: 10px;
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
/* 去除webkit中input的type="number"时出现的上下图标 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
